<template>
  <div class="overflow-hidden">
    <v-app-bar
      :color="appBarColor"
      class="px-sm text-left shadow-sm"
      flat
      height="200"
      absolute
      prominent
    >
      <v-container class="d-flex align-center mt-5">
        <v-progress-linear
          :active="getThemeMode.isLoading"
          :indeterminate="getThemeMode.isLoading"
          absolute
          bottom
          color="primary"
        />

        <img
          alt="logo"
          @click="goToHome"
          style="cursor: pointer"
          src="@/assets/images/logo/logo-light.png"
          class="ma-0 unselectable non-draggable"
          :height="$vuetify.breakpoint.smAndUp ? '45' : '35'"
        />

        <v-spacer />
        <div v-if="isAuthenticated" class="d-flex align-center gap-2">
          <v-tooltip bottom v-if="!!userPermissions && userPermissions.length">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon to="/admin">
                <v-icon color="white">mdi-shield-crown</v-icon>
              </v-btn>
            </template>
            <span>Organizações</span>
          </v-tooltip>
          <v-menu offset-y left min-width="150">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color dark v-bind="attrs" v-on="on" icon>
                <base-avatar
                  :seed="user.id"
                  :src="user.photo"
                  :size="$vuetify.breakpoint.smAndUp ? 40 : 35"
                />
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="userDrawer = true">
                <v-list-item-title>
                  <v-icon class="mr-1">mdi-account</v-icon>
                  Conta
                </v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'app.index' }">
                <v-list-item-title>
                  <v-icon class="mr-1">mdi-ticket</v-icon>
                  Meus ingressos
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="this.$vuetify.theme.dark"
                @click="toggleDarkMode()"
              >
                <v-list-item-title>
                  <v-icon class="mr-1">mdi-lightbulb-on-outline</v-icon>
                  Modo claro
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-else @click="toggleDarkMode()">
                <v-list-item-title>
                  <v-icon class="mr-1">mdi-moon-waning-crescent</v-icon>
                  Modo Escuro
                </v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'admin.index' }">
                <v-list-item-title>
                  <v-icon class="mr-1">mdi-shield-crown</v-icon> Organizações
                </v-list-item-title>
              </v-list-item>
              <v-divider class="my-1"></v-divider>
              <v-list-item @click="logoutProcess()">
                <v-list-item-title>
                  <v-icon class="mr-1">mdi-logout</v-icon> Sair
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <template v-else>
          <v-btn color="primary" class="black--text" @click="login">
            Entrar
          </v-btn>
        </template>
      </v-container>
    </v-app-bar>
    <!-- userDrawer -->
    <v-navigation-drawer
      v-if="isAuthenticated"
      v-model="userDrawer"
      fixed
      right
      height="100%"
      temporary
      floating
      width="350"
    >
      <user-drawer>
        <template v-slot:userDrawerCloseButton>
          <v-btn icon color @click.stop="userDrawer = !userDrawer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </user-drawer>

      <template v-slot:append>
        <div class="my-4 mx-4">
          <v-btn @click="logoutProcess()" block text>
            <v-icon left>mdi-logout</v-icon>
            Sair
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { appBarColor } from "@/themeConfig.js";

export default {
  name: "VerticallAppBar",
  components: {
    UserDrawer: () => import("../common-drawer/UserDrawer.vue"),
  },
  computed: {
    ...mapGetters(["getThemeMode"]),
    ...mapGetters("auth", ["user", "isAuthenticated", "userPermissions"]),
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions(["changeThemeDarkMode"]),
    login() {
      this.$router.push({
        name: "sign-in",
        query: { redirect: window.location.pathname },
      });
    },
    goToHome() {
      this.$router.push({ name: "home.index" });
    },
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.changeThemeDarkMode(this.$vuetify.theme.dark);
    },
    logoutProcess() {
      this.logout();
      this.$router.push({ name: "sign-in" });
    },
  },
  data() {
    return {
      userDrawer: false,
      appBarColor,
    };
  },
};
</script>
